import { useEffect } from "react";
import { useAnimationObserver } from "app/components/animations/animation-observer-provider";

/**
 * Refs passed to this hook will cause their element
 * to add the value of their `data-animation` attribute
 * among its classes when the animation observer finds
 * out that it is visible in the viewport.
 * @param {Array<React.MutableRefObject>} refs An array of element refs.
 */
export function useAnimation(refs) {
  const { observe } = useAnimationObserver();

  useEffect(() => {
    for (const ref of refs) {
      observe(ref.current);
    }
  }, [refs]);
}
